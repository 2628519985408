import {defineStore} from 'pinia'

export const useMenu = defineStore('menu',
{
   state: () =>  ({
    isOpen: false,
    pegableNotificacione: false,
    isNumberMobile: false,
    pegable: false,
    menu:[
     

      { id: 0, name:'Inicio', to: '/',
        class:'font-WT flex items-center border-b-[1px] border-solid border-black/20 hover:text-[#0A47ED] hover:fill-[#0A47ED]',
        viewBox:'0 0 20 19',
        width:'20',
        height:'19',
        isActive:true,
        path:`<path fill-rule="evenodd" clip-rule="evenodd" d="M4.40008 7.89996L9.80103 2.49901L15.202 7.89996H15.2011V16.9H4.40101V7.89996H4.40008ZM2.60101 9.69903L1.27188 11.0282L0 9.75628L8.52932 1.22696C9.23167 0.524608 10.3704 0.524608 11.0728 1.22696L19.6021 9.75628L18.3302 11.0282L17.0011 9.69903V16.9C17.0011 17.8941 16.1952 18.7 15.2011 18.7H4.40101C3.4069 18.7 2.60101 17.8941 2.60101 16.9V9.69903Z" />`
      
      },

      { id: 1, name:'Equipo', to: '/equipo',
        class:'font-WT flex items-center border-b-[1px] border-solid border-black/20 hover:text-[#0A47ED] hover:fill-[#0A47ED]',
        viewBox:'0 0 18 19',
        width:'18',
        height:'19',
        isActive:false,
        path:`<path fill-rule="evenodd" clip-rule="evenodd" d="M9 18.7002C4.02944 18.7002 0 14.6708 0 9.7002C0 4.72963 4.02944 0.700195 9 0.700195C13.9706 0.700195 18 4.72963 18 9.7002C18 14.6708 13.9706 18.7002 9 18.7002ZM15.0542 13.893C15.8797 12.7032 16.3635 11.2582 16.3635 9.70033C16.3635 5.63351 13.0667 2.33669 8.99989 2.33669C4.93306 2.33669 1.63625 5.63351 1.63625 9.70033C1.63625 11.2583 2.12011 12.7033 2.94576 13.8932C3.86338 12.6809 6.01571 12.1582 8.96983 12.1547C7.15218 12.1407 5.72725 10.8527 5.72725 8.06392C5.72725 6.22773 7.01924 4.79119 8.99998 4.79119C10.9752 4.79119 12.2727 6.36339 12.2727 8.22756C12.2727 10.8959 10.831 12.1409 9.03033 12.1547C11.9842 12.1583 14.1365 12.6809 15.0542 13.893ZM13.8802 15.2145C13.6408 14.3535 11.9205 13.7911 9.00004 13.7911C6.07935 13.7911 4.35898 14.3535 4.11978 15.2148C5.41902 16.3654 7.12788 17.064 8.99989 17.064C10.872 17.064 12.581 16.3653 13.8802 15.2145ZM7.36375 8.06406C7.36375 9.92072 8.03319 10.5186 9.00011 10.5186C9.96369 10.5186 10.6365 9.94456 10.6365 8.22769C10.6365 7.20531 9.99474 6.42769 9.00011 6.42769C7.96408 6.42769 7.36375 7.09519 7.36375 8.06406Z"/>`
      },

      { id: 2, name:'Reporte', to: '/reporte',
        class:'font-WT flex items-center  hover:text-[#0A47ED] hover:fill-[#0A47ED]',
        viewBox:'0 0 15 19',
        width:'15',
        height:'19',
        isActive:false,
        path:`<path fill-rule="evenodd" clip-rule="evenodd" d="M1.63636 0.700195H10.1571L14.7273 5.27038V17.0638C14.7273 17.9676 13.9946 18.7002 13.0909 18.7002H1.63636C0.732625 18.7002 0 17.9676 0 17.0638V2.33656C0 1.43282 0.732625 0.700195 1.63636 0.700195ZM8.18182 2.33656H1.63636V17.0638H13.0909V7.24565H9.81818C8.91444 7.24565 8.18182 6.51302 8.18182 5.60929V2.33656ZM9.81818 2.67546V5.60929H12.752L9.81818 2.67546Z"/> `
      },

   
    ]
   }),
   
    
  getters: {
    getIsOpen: ( state ) => state.isOpen,
    getIsMobile: ( state ) => state.isNumberMobile,
    
  },

   actions: {

    openMenu() {
      this.$state.isOpen = !this.$state.isOpen;
    },
    openNotificacion() {
      this.pegableNotificacione = !this.pegableNotificacione 
      if ( process.client) {
        localStorage.setItem(`isOpenNotifacion`, JSON.stringify(this.pegableNotificacione));
      }
    }
  }
})

